import React from 'react';
import c1 from "../../assets/images/c1.png"
//import tx from "../../assets/images/tx.png"
//import c2 from "../../assets/images/c2.png"
//import c3 from "../../assets/images/c3.png";
//import c4 from "../../assets/images/c4.png";
import {Link} from 'gatsby';
import { StaticQuery, graphql, navigate } from "gatsby"

const Banner2 = () => (
   <StaticQuery
         query={graphql`
            query {
               allWpCaseStudyCategory {
                  edges {
                    node {
                      name
                      slug
                      caseStudies {
                        nodes {
                          content
                          title
                          slug
                          CaseStudyFields {
                            activeUsersCount
                            buttonText
                            fundingRaised
                            monthlyIncome
                            logo {
                              sourceUrl
                            }
                            services {
                              websiteLink
                              visitWebsite
                              title
                            }
                            socialShare {
                              shareOn {
                                url
                                title
                                icon
                              }
                            }
                            availableOn {
                              icon
                              appLink
                            }
                            background {
                              title
                              description
                            }
                          }
                        }
                      }
                      description
                    }
                  }
                }
            }
         `}
         render={data => {
           return(
            data 
            && data.allWpCaseStudyCategory 
            && data.allWpCaseStudyCategory.edges &&
            data.allWpCaseStudyCategory.edges.map((casestudy, key)=>{
                 return(
                  <section id={casestudy.node.slug} key={key} className={key%2 === 0 ? "mobile-sec py-4":"software-bg"}>
                  <div className="container">
                    <div className="row mt-5 mb-md-5 mb-sm-3">
                       <div className="case-heading d-flex align-items-center w-100">
                          <span>
                             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 23.625V4.375C21 3.4085 20.2165 2.625 19.25 2.625L8.75 2.625C7.7835 2.625 7 3.4085 7 4.375L7 23.625C7 24.5915 7.7835 25.375 8.75 25.375H19.25C20.2165 25.375 21 24.5915 21 23.625Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14 7.875C14.7249 7.875 15.3125 7.28737 15.3125 6.5625C15.3125 5.83763 14.7249 5.25 14 5.25C13.2751 5.25 12.6875 5.83763 12.6875 6.5625C12.6875 7.28737 13.2751 7.875 14 7.875Z" fill="white"/>
                             </svg>
                          </span>
                          <h1 className={key%2===0?"mb-0":"mb-0 text-light"}>{casestudy.node.name}</h1>
                       </div>
                       <div className="col-lg-5 mt-md-4 mt-sm-2 heading-pera">
                          <p>{casestudy.node.description}</p>
                       </div>
                    </div>
                    <div className="row">
                       {casestudy.node.caseStudies.nodes.map((casestudy, key)=>{
                        return(
                           <div key={key} className="col-sm-6 col-md-6 col-lg-6">
                              <div className="case-items mb-4">
                                 <div className="pro-img mb-3">
                                    <img src={c1} alt="" />
                                    <div class="views d-flex justify-content-end">
                                       <span className="p-1">
                                          {casestudy.CaseStudyFields.activeUsersCount}
                                       </span>
                                       <span class="up-one p-1">
                                           {casestudy.CaseStudyFields.fundingRaised}
                                       </span>
                                       <span class="up-two p-1">
                                           {casestudy.CaseStudyFields.monthlyIncome}
                                       </span>
                                    </div>
                                 </div>
                                 <div class="px-4 case-content-outer">
                                    <div class="d-flex justify-content-between align-items-center py-3 ">
                                       <div class="care-title d-flex align-items-center">
                                          <span>  
                                              <img className="w-100" src={casestudy.CaseStudyFields.logo.sourceUrl} alt="video"/>
                                          </span>
                                          <h4 class="mb-0">{casestudy.title.substring(0, 10) + "..."}</h4>
                                       </div>
                                       {casestudy 
                                       && casestudy.CaseStudyFields 
                                       && casestudy.CaseStudyFields.availableOn&&<div class="store d-flex  align-items-center ">
                                          <p class="mb-0">Available on:</p>
                                          {casestudy 
                                            && casestudy.CaseStudyFields 
                                            && casestudy.CaseStudyFields.availableOn
                                            && casestudy.CaseStudyFields.availableOn.map((share,key)=>{
                                             return(
                                                <Link to={share.appLink}><span dangerouslySetInnerHTML={{__html:share.icon}} key={key} class="mx-3"></span></Link>  
                                             )
                                           })}
                                       </div>}
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html:casestudy.content}} class="case-disp pb-3 "></div>
                                    <button onClick={()=>{navigate(`/casestudy/${casestudy.slug}`)}} class="estimate-btn btn btn-bg btn-design text-white px-4 mb-4 ">
                                          Read Case Study 
                                    </button>
                                 </div>
                              </div>
                           </div>
                          )
                       })}
                       
                    </div>
                    {/*<div class=" text-center">
                       <button class="show-more my-4">Show More</button>
                     </div>*/}
                 </div>
                 <div class="mobile-bg">
                    <svg width="540" height="227" viewBox="0 0 540 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M77.7965 9.85423L-47.5 146.5V226.5L540 130.5L108.508 1.38836C97.4943 -1.90716 85.5659 1.38098 77.7965 9.85423Z" fill="#F4F8FF"/>
                    </svg>
                 </div>
                </section>
                 )
            })
             
            )
         }}
      />
  
)

export default Banner2