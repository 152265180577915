import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/casestudy/banner";
import Banner2 from "../components/casestudy/banner2";
//import Software from "../components/casestudy/software";
//import Website from "../components/casestudy/website";
import Testimonial from "../components/home/testimonial";

const CaseStudy = (props) => (
    <Layout props={props}>
        <SEO title="Casestudy"/>
        <div class="col-12 wrapper main-bggrey p-0">
            <div class="col-12 inner-page-content home-inner  px-0">
                  <Banner/> 
            </div>
        </div>
        <Banner2/>
        <Testimonial/>
    </Layout>
)

export default CaseStudy;

