import React from 'react';
import caseimg from "../../assets/images/case.png";
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
      <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug:{eq:"case-studies"}}) {
                  edges {
                    node {
                      TeamlanceCaseStudiesOptions {
                        headerOptions {
                          description
                          title
                          featuredImage {
                            sourceUrl
                            title
                          }
                        }
                      }
                    }
                  }
                }
               allWpCaseStudyCategory {
                  edges {
                  node {
                     name
                     slug
                     caseStudies {
                        nodes {
                        content
                        title
                        slug
                        CaseStudyFields {
                           activeUsersCount
                           buttonText
                           fundingRaised
                           monthlyIncome
                           logo {
                              sourceUrl
                           }
                           services {
                              websiteLink
                              visitWebsite
                              title
                           }
                           socialShare {
                              shareOn {
                              url
                              title
                              icon
                              }
                           }
                           availableOn {
                              icon
                              appLink
                           }
                           background {
                              title
                              description
                           }
                        }
                        }
                     }
                     description
                  }
                  }
               }
            }
         `}
         render={data => {
            return(
               
               <section className="case-banner pt-4">
                {data 
                 && data.allWpPage 
                 && data.allWpPage.edges[0] &&<div className="container">
                  <div className="row">
                     <div className="col-md-6 d-flex flex-column sectiontop-pd">
                        <div className="banner-text case-heading my-auto ml-0 ">
                           <h3 className="">{data.allWpPage.edges[0].node.TeamlanceCaseStudiesOptions.headerOptions.title}</h3>
                           <figure>
                              <svg width="306" height="17" viewBox="0 0 306 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M1 12.1113C1 12.1113 134 -6.00004 305 12.1112" stroke="#19B8F8" stroke-width="8" stroke-linejoin="round"/>
                              </svg>
                           </figure>
                           <p dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceCaseStudiesOptions.headerOptions.description}}></p>
                        </div>
                     </div>
                     <div className="col-md-6 py-5">
                        <div className="d-flex py-5 justify-content-center align-items-center  sectiontop-pd sectionbtm-pd case_banner_logo">
                           <img src={data.allWpPage.edges[0].node.TeamlanceCaseStudiesOptions.headerOptions.featuredImage.sourceUrl} alt="case study" />
                        </div>
                     </div>
                  </div>
               </div>}
               {data 
               && data.allWpCaseStudyCategory 
               && data.allWpCaseStudyCategory.edges &&<div className="container-fluid bg-white links-border">
                  <div className="container">
                     <div className="banner-links">
                        <ul className="m-0 p-0 d-flex">
                           <div  id="myaddCarouselservice" className="owl-carousel owl-theme d-flex justify-content-between w-100 flex-wrap">
                              {data.allWpCaseStudyCategory.edges.map((data,key)=>{
                                   return(
                                      <div key={key} className="item">
                                         <li>
                                            <a href={`#${data.node.slug}`}>{data.node.name}</a>
                                          </li>
                                      </div>  
                                   )
                              })}
                          </div>
                         </ul>
                       </div>  
                     </div>
                  </div>}
            </section>
            )
          }
        }
     />
)

export default Banner;